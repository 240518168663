import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import BasicSEO from '@/components/meta/meta';
import { Fragment } from 'react';
import { SUBSCRIPTION_PLANS_KEY } from '@/constants/reduxKeys';
import SignUpTeams from '@/components/signup/signupTeams';
import SignUpMainPMAlly from '@/components/signup/signupLayoutPMAlly';
import styled from 'styled-components';
import subscriptionPlansReducer from '@/redux/reducers/subscriptionPlansReducer';
import subscriptionPlansSaga from '@/redux/sagas/subscriptionPlansSaga';
import { withNonAuthServerSideProps } from '../../next/withSharedServerSide';

const LoadingContainer = styled.div`
    height: 100vh;

    &&& .ant-spin-nested-loading > div > .ant-spin {
        max-height: 100vh;
    }
`;

function SignUp() {
    useInjectReducer({
        key: SUBSCRIPTION_PLANS_KEY,
        reducer: subscriptionPlansReducer,
    });

    useInjectSaga({ key: SUBSCRIPTION_PLANS_KEY, saga: subscriptionPlansSaga });

    return (
        <Fragment>
            <BasicSEO title="Sign Up" description="Sign Up Page" />
            <LoadingContainer>
                <SignUpMainPMAlly>
                    <SignUpTeams />
                </SignUpMainPMAlly>
            </LoadingContainer>
        </Fragment>
    );
}

export const getServerSideProps = withNonAuthServerSideProps();

export default SignUp;
