import {
    chooseProvider,
    chooseProviderFailed,
    fixTypo,
    getProviderInformation,
    googleChosen,
    googleSuccessful,
    onCreateAccount,
    onRegisterBeta,
} from '@/redux/actions/signupProcessAction';
import { useDispatch, useSelector } from 'react-redux';

import ChoosePasswordViewPMAlly from '@/components/signup/choosePasswordPMAlly/choosePasswordViewPMAlly';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import TeamsChooseProviderView from '@/components/signup/pmAllyChooseProvider/teamsChooseProviderView';

function SignUpTeams() {
    const state = useSelector((rootState) => rootState.signup);
    const [fullname, setFullname] = useState(null);
    const [company, setCompany] = useState(null);
    const [teamsize, setTeamsize] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [passwordVal, setPasswordVal] = useState(null);

    const dispatch = useDispatch();
    const {
        query: {
            provider: providerFromQuery,
            error,
            error_description: errorDescription,
            email,
            accessToken,
            refreshToken,
            conversationId,
            id,
            tokenId,
            mode,
            callback,
            displayName,
            objectId,
            tenantId,
            preferredUserName,
        },
    } = useRouter();

    const router = useRouter();

    const { email: urlEmail } = router.query;

    if(urlEmail) {
        console.log('urlEmail', urlEmail);
    } else {
        console.log('no urlEmail');
    }

    useEffect(() => {
        if (providerFromQuery) {
            dispatch(
                getProviderInformation(
                    providerFromQuery,
                    email,
                    accessToken,
                    refreshToken,
                    conversationId || id,
                ),
            );
        }
    }, [accessToken, dispatch, email, providerFromQuery, refreshToken, conversationId, id]);

    useEffect(() => {
        if (error) {
            if (errorDescription) {
                notification.error({
                    message: 'Error',
                    description: errorDescription,
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Whoops! Your Signup has failed!',
                });
            }
        }
    }, [error, errorDescription]);

    useEffect(() => {
        if (mode?.length) {
            localStorage.setItem('mode', mode);
        }
        if (callback?.length) {
            localStorage.setItem('callback', callback);
        }
        if (displayName?.length) {
            localStorage.setItem('displayName', displayName);
        }
        if (objectId?.length) {
            localStorage.setItem('objectId', objectId);
        }
        if (tenantId?.length) {
            localStorage.setItem('tenantId', tenantId);
        }
        if (preferredUserName?.length) {
            localStorage.setItem('preferredUserName', preferredUserName);
        }
    }, [mode, callback, displayName, objectId, tenantId, preferredUserName]);

    switch (state?.type) {
        case 'CHOOSE_PROVIDER':
            return (
                <TeamsChooseProviderView
                    onGoogleChosen={() => dispatch(googleChosen())}
                    onGoogleFailed={() => dispatch(chooseProviderFailed())}
                    onGoogleSuccessful={(r) => {
                        dispatch(googleSuccessful(r, null, conversationId || id))
                    }}
                    onChooseProvider={(provider) =>{
                        console.log('onChooseProvider');
                        dispatch(chooseProvider(provider, null, conversationId || id))
                    }}
                    onCreateAccount={(newAccountEmail, onError, fullnameValue, companyValue, teamsizeValue, passwordValue, selectedPlanValue) =>{
                        console.log('onCreateAccount', newAccountEmail, fullnameValue, companyValue, teamsizeValue);
                        setFullname(fullnameValue);
                        setCompany(companyValue);
                        setTeamsize(1);
                        setPasswordVal(passwordValue);
                        setSelectedPlan(selectedPlanValue);
                        dispatch(onCreateAccount(newAccountEmail, onError))
                    }}
                    urlEmail={urlEmail}
                    tokenId={tokenId}
                />
            );
        case 'ENTER_NAME':
            return (
                <ChoosePasswordViewPMAlly
                    onRegister={(
                        firstName,
                        lastName,
                        password,
                        reCaptchaToken,
                        companyValue,
                        teamsizeValue,
                    ) =>
                        dispatch(
                            onRegisterBeta(
                                firstName,
                                lastName,
                                password,
                                reCaptchaToken,
                                companyValue,
                                teamsizeValue,
                                conversationId || id,
                                selectedPlan,
                            ),
                        )
                    }
                    provider={state.provider}
                    onFixTypo={() => dispatch(fixTypo())}
                    fullname={fullname}
                    company={company}
                    teamsize={teamsize}
                    passwordVal={passwordVal}
                    conversationId={conversationId || id}
                    selectedPlan={selectedPlan}
                />
            );
        default:
            return null;
    }
}

export default SignUpTeams;
